@use '@angular/material' as mat;
@import '@angular/material/theming';
@include mat.core();

$md-primary: (
    50 : #eaf3f9,
    100 : #cbe2f0,
    200 : #a9cee6,
    300 : #87badb,
    400 : #6dacd4,
    500 : #539dcc,
    600 : #4c95c7,
    700 : #428bc0,
    800 : #3981b9,
    900 : #296fad,
    A100 : #eef7ff,
    A200 : #bbddff,
    A400 : #88c4ff,
    A700 : #6eb8ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-secondary: (
    50 : #eaf3f9,
    100 : #cbe2f0,
    200 : #a9cee6,
    300 : #87badb,
    400 : #6dacd4,
    500 : #539dcc,
    600 : #4c95c7,
    700 : #428bc0,
    800 : #3981b9,
    900 : #296fad,
    A100 : #eef7ff,
    A200 : #bbddff,
    A400 : #88c4ff,
    A700 : #6eb8ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-warn: (
    50 : #feeee7,
    100 : #fcd5c3,
    200 : #faba9b,
    300 : #f89e73,
    400 : #f78955,
    500 : #f57437,
    600 : #f46c31,
    700 : #f2612a,
    800 : #f05723,
    900 : #ee4416,
    A100 : #ffffff,
    A200 : #ffeeeb,
    A400 : #ffc5b8,
    A700 : #ffb09e,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$test-app-primary: mat.define-palette($md-primary, 500, 500, 500);
$test-app-accent: mat.define-palette($md-secondary, 900, 700, A500);
$test-app-warn: mat.define-palette($md-warn, 900, A400, A500);

$test-app-theme: mat.define-light-theme($test-app-primary, $test-app-accent, $test-app-warn);

@include mat.all-component-themes($test-app-theme);

.validation-div {
    color: mat.get-color-from-palette($test-app-warn);
}


.ddp-activity-validation {
    @extend .ddp-activity-validation;
    width: 100%;
    margin: 0;
}